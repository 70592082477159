import { VisitorInfo, resolveUsersVisitorInfo } from './visitor-info';

function getUser(): User {
    if ( !window.user ) {
        window.user = new User( window.userStub || {} );
    }
    return window.user;
}

function setUser( user: User ) {
    window.user = user;
}

class User {
    isVerified = false;
    _visitorInfo?: VisitorInfo;

    constructor( userStub: UserStub ) {
        this.isVerified = userStub.isVerified || false;
    }

    async visitorInfo(): Promise<VisitorInfo> {
        if ( !this._visitorInfo ) {
            this._visitorInfo = await resolveUsersVisitorInfo();
            this._persistSelf();
        }
        return this._visitorInfo;
    }

    setVisitorInfo( vInfo: VisitorInfo ) {
        this._visitorInfo = vInfo;
    }

    hasVisitorInfo(): boolean {
        return !!this._visitorInfo;
    }

    _persistSelf(): void {
        setUser( this );
    }
}

export {
    User,
    getUser,
};
