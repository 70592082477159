import Logger from '../logging/logger';
import { getLanguage } from '../user/device-info';

function getCurrentPagePath(): string {
    return document.location.pathname + document.location.search + document.location.hash;
}

/**
 * @param {String} selector - the element selector
 * @param {String} key - the query key
 * @param {String} value - the query value
 */
function updateURLs( selector: string, key: string, value: string, container = document ): void {
    if ( !container ) {
        container = document;
    }

    const elements = container.querySelectorAll( selector ) as NodeListOf<HTMLElement>;

    for ( let i = 0; i < elements.length; i++ ) {
        let path = '';

        if ( elements[ i ].getAttribute( 'href' ) ) {
            path = elements[ i ].getAttribute( 'href' ) as string;
        } else if ( elements[ i ].dataset && elements[ i ].dataset.actionUrl ) {
            path = elements[ i ].dataset.actionUrl as string;
        }

        if ( !path ) {
            continue;
        }

        if ( path.startsWith( '/' ) ) {
            path = window.location.origin + path;
        }

        let updatedURL: URL;

        try {
            updatedURL = new URL( path );
        } catch ( err ) {
            Logger.error( 'failed constructing URL for path ' + path );
            throw err;
        }

        updatedURL.searchParams.set( key, value );

        if ( elements[ i ].getAttribute( 'href' ) ) {
            elements[ i ].setAttribute( 'href', updatedURL.toString() );
        } else if ( elements[ i ].dataset && elements[ i ].dataset.actionUrl ) {
            elements[ i ].setAttribute( 'data-action-url', updatedURL.toString() );
        }
    }
}

/**
 * assetURL returns an absolute URL for the path provided
 * using the asset prefix if it has been set
 * @param {String} path - the relative URL path
 * @returns URL - the absolute URL
 */
function assetURL( path: string ): URL {
    let prefix = window.location.origin;

    if ( window.assetsPrefix.length > 0 ) {
        prefix = window.assetsPrefix;
    }

    if ( !path.startsWith( '/' ) ) {
        prefix += '/';
    }

    return new URL( prefix + path );
}

/**
 * @param {URL} baseURL
 * @param {String} path
 */
function urlWithLanguage( baseURL: URL, path: string ): URL {
    const language = getLanguage();

    if ( !path.startsWith( '/' ) ) {
        path = '/' + path;
    }

    if ( language !== 'en' ) {
        path = '/' + language + path;
    }

    return new URL( path, baseURL );
}

export {
    getCurrentPagePath,
    updateURLs,
    assetURL,
    urlWithLanguage,
};
