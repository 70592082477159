import { closeAccountMenu, closeSideNav, openSideNav } from '../popups/utils';
import requestIdleCallback from '../shims/requestIdleCallback';

export default function SideNavigation(): void {
    const burgerMenu = document.getElementById( 'burger-menu' ) as HTMLButtonElement | null;
    const sideNavClose = document.querySelector( '#mobile-side-nav-close' ) as HTMLButtonElement | null;
    const sideNavigationContainer = document.querySelector( '.sidenav' );

    if ( !burgerMenu || !sideNavClose || !sideNavigationContainer ) {
        return;
    }

    const handleInnerClicks = async function( event: Event ) {
        if ( event.target && event.target instanceof HTMLElement && event.target.matches( '.sidenav button' ) )  {
            return;
        }
        await closeSideNav();
    };

    const close = async function( event: Event ) {
        await closeSideNav();
        sideNavigationContainer.querySelectorAll( 'a,button' ).forEach( clickable => clickable.removeEventListener( 'click', handleInnerClicks ) );
    };

    burgerMenu.addEventListener( 'click', async ( event: Event ) => {
        await openSideNav();

        requestIdleCallback( () => {
            sideNavigationContainer.querySelectorAll( 'a,button' ).forEach( clickable => clickable.addEventListener( 'click', handleInnerClicks ) );

            closeAccountMenu();
        } );
    } );

    sideNavClose.addEventListener( 'click', close );

    sideNavigationContainer.addEventListener( 'click', ( event: Event ) => {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.matches( '.sidenav' ) ) {
            return;
        }
        close( event );
    } );
}
