import { calculateElementHeaderOffset } from '../header/offset';
import { isTouchDevice } from '../user/device-info';

class HomepageHeroSearch {
    _form: HTMLFormElement;
    _offerTypeInput: HTMLInputElement | null;
    _destinationInput: HTMLInputElement;
    _typeaheadInput: HTMLInputElement;
    _searchbar: HTMLElement;
    _scrollOffset: number | null = null;
    _scrollTimeout: number;

    constructor( form: HTMLFormElement ) {
        this._form = form;
        this._offerTypeInput = this._form.querySelector( 'input[name=offer]' ) as HTMLInputElement;
        this._destinationInput = this._form.querySelector( 'input[name=destination]' ) as HTMLInputElement;
        this._typeaheadInput = this._form.querySelector( 'input[name=locationFreeText]' ) as HTMLInputElement;
        this._searchbar = this._form.querySelector( '.searchhero__searchbar' ) as HTMLElement;

        const btns = this._form.querySelectorAll( '.searchhero__headerbtn' );
        btns.forEach( ( btn: HTMLButtonElement ) => btn.addEventListener( 'click', this._onOfferTypeChange.bind( this ) ) );

        if ( this._typeaheadInput && this._searchbar && isTouchDevice() ) {
            window.addEventListener( 'scroll', this._onScroll.bind( this ), { passive: true } );
            this._typeaheadInput.addEventListener( 'focus', this._onTypeaheadFocus.bind( this ) );
        }

        this._form.addEventListener( 'submit', this._onSubmit.bind( this ) );
    }

    _onSubmit( event: SubmitEvent ): void {
        event.preventDefault();

        if ( event.submitter && event.submitter instanceof HTMLButtonElement && event.submitter.dataset.destination ) {
            const destination = event.submitter.dataset.destination;
            this._destinationInput.value = destination;
        }

        this._form.submit();
    }

    _onOfferTypeChange( event: Event ): void {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }

        if ( event.target.classList.contains( 'searchhero__headerbtn--selected' ) ) {
            return;
        }

        const activeBtn = document.querySelector( '.searchhero__headerbtn--selected' );

        if ( activeBtn ) {
            activeBtn.classList.remove( 'searchhero__headerbtn--selected' );
        }

        const target = event.target as HTMLButtonElement;

        ( this._offerTypeInput as HTMLInputElement ).value = target.dataset.offer || '';
        event.target.classList.add( 'searchhero__headerbtn--selected' );

        const searchLink = document.querySelector( '#below-h1-text' ) as HTMLAnchorElement | null;

        if ( searchLink && target.dataset.relatedUrl && target.dataset.relatedText ) {
            searchLink.href = target.dataset.relatedUrl;
            searchLink.innerHTML = target.dataset.relatedText;
        }
    }

    _onScroll( event: Event ): void {
        if ( this._scrollTimeout !== null ) {
            window.clearTimeout( this._scrollTimeout );
        }

        this._scrollTimeout = window.setTimeout( () => {
            this._scrollOffset = calculateElementHeaderOffset( this._searchbar );
        }, 200 );
    }

    _onTypeaheadFocus( event: Event ): void {
        if ( !this._scrollOffset ) {
            this._scrollOffset = calculateElementHeaderOffset( this._searchbar );
        }

        if ( !this._scrollOffset ) {
            return;
        }

        window.setTimeout( () => {
            window.scrollTo( {
                top: this._scrollOffset as number,
            } );
        } );
    }
}

export default HomepageHeroSearch;
