import { getOnPopupScroll } from '../events/popup-open';

function getPagePopupContainer(): HTMLElement {
    let popupContainer = document.querySelector( '#page-popup-container' ) as HTMLElement | null;

    if ( !popupContainer ) {
        const template = document.createElement( 'template' );
        template.innerHTML = `
            <section id="page-popup-container" class="pagepopup__wrapper">
                <div class="pagepop__scrollcontainer">
                    <div class="pagepopup__container pagepopup__container--details container">
                        <div class="pagepopup__content"></div>
                        <div class="pagepopup__loader"><div class="pagepopup__spinner"></div></div>
                    </div>
                </div>
            </section>
        `;
        popupContainer = document.body.appendChild( template.content.children[ 0 ] ) as HTMLElement;
    }

    const popupScrollContainer = popupContainer.querySelector( '.pagepop__scrollcontainer' ) as HTMLElement | null;

    if ( popupScrollContainer && !popupScrollContainer.dataset.scrollListener ) {
        popupScrollContainer.addEventListener( 'scroll', getOnPopupScroll(), { passive: true } );
        popupScrollContainer.dataset.scrollListener = 'true';
    }

    return popupContainer;
}

export {
    getPagePopupContainer,
};
