import * as Sentry from '@sentry/browser';

export default function InitOneTrustPreferenceButtons(): void {
    document.querySelectorAll( '[data-action="open-cookie-preferences"]' ).forEach( ( btn: HTMLButtonElement ) => {
        btn.addEventListener( 'click', ( event: Event ) => {
            if ( !window.OneTrust ) {
                Sentry.captureException( 'cannot launch OneTrust preferences as js is missing' );
                return;
            }
            window.OneTrust.ToggleInfoDisplay();
        } );
    } );
}

export function isConsentBannerVisible(): boolean {
    const banner = document.querySelector( '#onetrust-banner-sdk' ) as HTMLElement | null;

    if ( !banner ) {
        return false;
    }

    return banner.style.display !== 'none';
}

export function isContentPreferencesVisible(): boolean {
    const popup = document.querySelector( '#onetrust-pc-sdk' ) as HTMLElement | null;

    if ( !popup ) {
        return false;
    }

    return popup.style.display !== 'none';
}
