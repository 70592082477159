import * as Sentry from '@sentry/browser';
import { upsertHiddenInput } from '../dom/create';

function addRecaptchaField( form: HTMLFormElement, value: string ): HTMLInputElement {
    return upsertHiddenInput( form, 'g-recaptcha-response', value );
}

function loadRecaptcha( action: string ): Promise<string> {
    return new Promise( ( resolve, reject ) => {
        if ( !window.grecaptcha ) {
            reject( new Error( 'cannot load captcha token as grecaptcha is undefined' ) );
        }

        window.grecaptcha.ready( async () => {
            const token = await window.grecaptcha.execute( window.recaptchaSiteKeyV3, {
                action: action,
            } );

            const previousCaptchaTokens = getPreviousCaptchaTokens();

            if ( previousCaptchaTokens.includes( token ) ) {
                Sentry.captureException( 'duplicate captcha token loaded' );
            }

            previousCaptchaTokens.push( token );

            window.appStorage.setSessionItem( 'captchaTokens', JSON.stringify( previousCaptchaTokens ) );

            resolve( token );
        } );
    } );
}

function renderRecaptcha( selector: string, parameters: ReCaptchaV2.Parameters = {}, existingWidgetID?: number ): Promise<number> {
    return new Promise( ( resolve, reject ) => {
        if ( !window.grecaptcha ) {
            reject( new Error( 'cannot load captcha token as grecaptcha is undefined' ) );
            return;
        }

        const renderContainer = document.querySelector( selector ) as HTMLElement | null;

        if ( !renderContainer ) {
            reject( new Error( 'captcha render container element not found' ) );
            return;
        }

        const container = renderContainer.closest( '.captchacontainer' );

        if ( !container ) {
            reject( new Error( 'captcha container element not found' ) );
            return;
        }

        const form = container.closest( 'form' );

        if ( form ) {
            form.querySelectorAll( '[name="g-recaptcha-version"]' ).forEach( ( captchaInput: HTMLInputElement | HTMLTextAreaElement ) => {
                captchaInput.value = '';
            } );
        }

        const parametersMerged = Object.assign( {}, {
            sitekey: window.recaptchaSiteKeyV2,
            type: 'image',
        }, parameters );

        parametersMerged.callback = function( response: string ): void {
            container.classList.add( 'captchacontainer--passed' );

            if ( form ) {
                const previousCaptchaResponseElement: HTMLInputElement | null = form.querySelector( 'input[type="hidden"][name="g-recaptcha-response"]' );

                if ( previousCaptchaResponseElement ) {
                    previousCaptchaResponseElement.value = response;
                }

                let captchaVersionInput = form.querySelector( 'input[name="g-recaptcha-version"]' ) as HTMLInputElement | null;

                if ( captchaVersionInput ) {
                    captchaVersionInput.value = 'v2';
                } else {
                    captchaVersionInput = document.createElement( 'input' );
                    captchaVersionInput.type = 'hidden';
                    captchaVersionInput.name = 'g-recaptcha-version';
                    captchaVersionInput.value = 'v2';
                    form.appendChild( captchaVersionInput );
                }
            }

            if ( typeof parameters.callback === 'function' ) {
                parameters.callback( response );
            }
        };

        parametersMerged[ 'expired-callback' ] = function(): void {
            if ( form ) {
                form.querySelectorAll( '[name="g-recaptcha-response"]' ).forEach( ( captchaElements: HTMLInputElement | HTMLTextAreaElement ) => {
                    captchaElements.value = '';
                } );
            }

            if ( typeof parameters[ 'expired-callback' ] === 'function' ) {
                parameters[ 'expired-callback' ]();
            }
        };

        parametersMerged[ 'error-callback' ] = function(): void {
            if ( form ) {
                form.querySelectorAll( '[name="g-recaptcha-response"]' ).forEach( ( captchaElements: HTMLInputElement | HTMLTextAreaElement ) => {
                    captchaElements.value = '';
                } );
            }

            if ( typeof parameters[ 'error-callback' ] === 'function' ) {
                parameters[ 'error-callback' ]();
            }
        };

        const widgetID = window.grecaptcha.render( renderContainer, parametersMerged );

        resolve( widgetID );

        container.classList.add( 'captchacontainer--visible' );
    } );
}

function getPreviousCaptchaTokens(): Array<string> {
    if ( window.appStorage.getSessionItem( 'captchaTokens' ) ) {
        return JSON.parse( window.appStorage.getSessionItem( 'captchaTokens' ) ) as Array<string>;
    }

    window.appStorage.setSessionItem( 'captchaTokens', '[]' );

    return [];
}

export {
    addRecaptchaField,
    loadRecaptcha,
    getPreviousCaptchaTokens,
    renderRecaptcha,
};
