import Pristine from 'pristinejs';
import * as Ladda from 'ladda';
import { addRecaptchaField, loadRecaptcha } from '../captcha/google';
import { getDeviceInfo, getLanguage } from '../user/device-info';
import newValidator from '../validation';
import { setupEmailSuggestionFromResult } from '../validation/email-suggest';

const handledForms: Array<string> = [
    'update-mailings',
    'update-notifications',
];

function SimpleForm( form: HTMLFormElement ) {
    let validator: Pristine | null = null;

    if ( form.dataset.initialised && form.dataset.initialised === 'true' ) {
        return;
    }

    form.dataset.initialised = 'true';

    if ( form.dataset.simpleFormValidate && form.dataset.simpleFormValidate === 'true' ) {
        validator = newValidator( form );
    }

    const handleForm = async function( form: HTMLFormElement ) {
        if ( form.dataset.isProcessing && form.dataset.isProcessing === 'true' ) {
            return;
        }

        const messageContainer = form.querySelector( '.messages' );

        if ( !messageContainer ) {
            throw new Error( 'failed to find message container for form' );
        }

        messageContainer.innerHTML = '';

        form.dataset.isProcessing = 'true';

        const submitBtn = form.querySelector( 'button[type=submit]' ) as HTMLButtonElement;

        if ( !submitBtn ) {
            throw new Error( 'failed to find form submit button' );
        }

        submitBtn.disabled = true;

        Ladda.create( submitBtn ).start();

        const formName = form.dataset.formName as string;

        if ( validator !== null ) {
            const valid = validator.validate();

            if ( !valid ) {
                form.dataset.isProcessing = 'false';
                submitBtn.disabled = false;
                Ladda.stopAll();
                return;
            }
        }

        const reset = function() {
            form.querySelectorAll( 'input' ).forEach( ( formInput: HTMLInputElement ) => {
                if ( formInput.type === 'hidden' ) {
                    return;
                }

                if ( formInput.type === 'checkbox' ) {
                    formInput.checked = false;
                } else if ( formInput.value && formInput.value.length > 0 ) {
                    formInput.value = '';
                }
            } );

            form.querySelectorAll( 'select' ).forEach( ( select: HTMLSelectElement ) => {
                if ( select.dataset.defaultSelected ) {
                    select.value = select.dataset.defaultSelected;
                } else {
                    select.value = '';
                }
            } );

            form.querySelectorAll( 'textarea' ).forEach( ( txt: HTMLTextAreaElement ) => {
                if ( txt.dataset.defaultValue && txt.dataset.defaultValue.length > 0 ) {
                    txt.value = txt.dataset.defaultValue;
                } else {
                    txt.value = '';
                }
            } );
        };

        const sendRequest = async function() {
            const formData = new FormData( form );
            formData.append( 'deviceinfo', JSON.stringify( getDeviceInfo() ) );

            const url = new URL( window.location.origin + form.getAttribute( 'action' ) );

            const response = await window.http( url, {
                method: 'POST',
                body: formData,
            } );

            if ( !response.ok ) {
                throw new Error( `request for ${url} failed with status ${response.status}` );
            }

            const data = await response.json();

            form.dataset.isProcessing = 'false';
            Ladda.stopAll();

            const successful = ( data.success || data.refresh || data.redirect );

            const handled = handledForms.includes( formName );

            if ( successful && handled ) {
                document.dispatchEvent( new CustomEvent( 'twc:' + formName, {
                    bubbles: true,
                    detail: {
                        formName: formName,
                        form: form,
                    },
                } ) );
            }

            if ( data.success && form.dataset.simpleFormResetOnSubmit && form.dataset.simpleFormResetOnSubmit === 'true' ) {
                reset();
            }

            if ( successful && !handled && form.dataset.simpleFormSuccessEvent ) {
                window.dataLayerPush( JSON.parse( form.dataset.simpleFormSuccessEvent ) );
            } else if ( successful && !handled && formName ) {
                window.dataLayerPush( {
                    'event': 'form-submit',
                    'action': formName,
                    'label': 'lang:' + getLanguage(),
                    'value': 1,
                } );
            }

            if ( data.success && data.content ) {
                submitBtn.disabled = false;
                document.body.insertAdjacentHTML( 'beforeend', data.content );
            }

            const removeSubmitOnSuccess = form.dataset.simpleFormBlockOnSuccess === 'true';

            if ( data.success && !data.content && !removeSubmitOnSuccess ) {
                messageContainer.insertAdjacentHTML( 'beforeend', `<div class="alert alert-success simpleform__successmsg">${data.message}</div>` );
            }

            if ( data.success && removeSubmitOnSuccess ) {
                const submitBtnParent = submitBtn.parentElement;

                if ( !submitBtnParent ) {
                    throw new Error( 'failed to find parent element of submit button' );
                }

                submitBtn.classList.add( 'd-none' );

                const resetButton = document.createElement( 'button' );
                resetButton.type = 'button';
                resetButton.innerHTML = form.dataset.simpleFormBlockOnSuccessBtn as string;

                const msg = document.createElement( 'div' );
                msg.className = 'alert alert-success simpleform__successmsg mb-none';
                msg.innerHTML = data.message + ' ';
                msg.appendChild( resetButton );

                resetButton.addEventListener( 'click', ( event: Event ) => {
                    reset();
                    submitBtn.classList.remove( 'd-none' );
                    msg.remove();
                } );

                submitBtnParent.insertAdjacentElement( 'beforeend', msg );
            }

            if ( data.success && form.dataset.simpleFormOnSucces && form.dataset.simpleFormOnSucces.length > 0 ) {
                const events = form.dataset.simpleFormOnSucces.split( ',' );
                events.forEach( ( event: string ) => form.dispatchEvent( new CustomEvent( event, { bubbles: true } ) ) );
            }

            if ( !data.success && data.refresh ) {
                window.location.reload();
            }

            if ( !data.success && data.redirect ) {
                window.location = data.redirect;
            }

            if ( !data.success && data.errors && data.errors.form ) {
                messageContainer.insertAdjacentHTML( 'beforeend', `<div class="alert alert-danger">${data.errors.form}</div>` );
                window.scroll( 0, messageContainer.getBoundingClientRect().top + window.scrollY - 200 );
            }

            if ( data.didyoumean ) {
                setupEmailSuggestionFromResult( {
                    emailSuggestion: data.didyoumean,
                    html: data.didyoumeanhtml,
                }, form );
            }

            submitBtn.disabled = false;
        };

        const token = await loadRecaptcha( form.dataset.captchaAction as string );

        addRecaptchaField( form, token );
        sendRequest();
    };

    form.addEventListener( 'submit', function( event: Event ) {
        event.preventDefault();
        handleForm( form );
    } );
}

export default SimpleForm;
