import { parseBool } from '../strconv/boolean';

async function checkHasUserProvidedEmail(): Promise<boolean> {
    const url = new URL( window.location.origin + '/has-provided-email' );

    const response = await window.http( url );

    if ( !response.ok ) {
        return false;
    }

    const body = await response.json();

    return body.hasProvidedEmail;
}

async function hasUserProvidedEmail(): Promise<boolean> {
    const hasProvidedEmail = parseBool( window.appStorage.getSessionItem( 'hasProvidedEmail' ) );

    if ( !hasProvidedEmail ) {
        window.appStorage.setSessionItem( 'hasProvidedEmail', 'false' );
    }

    const providedEmail = await checkHasUserProvidedEmail();

    window.appStorage.setSessionItem( 'hasProvidedEmail', providedEmail.toString() || 'false' );

    return providedEmail;
}

export default hasUserProvidedEmail;
