import newFetch from './fetch';
import { logger, identify, sentryHook } from '../http/middleware';

export function getFetchAPI() {
    let http = newFetch( window.location.origin );

    if ( process.env.HTTP_LOGGING === 'true' ) {
        http = logger( http );
    }

    http = identify( http );

    http = sentryHook( http );

    return http;
}
