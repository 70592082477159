
import Router from '../routing/router';
import AppStorage from '../storage/app-storage';

import popupHandler from '../popups/handler';
import onPopupOpen from '../events/popup-open';
import onNewcontentloaded from '../events/new-content-loaded';
import onPageShow from '../events/pageshow';
import { onPopupClose, onPopupCloseAfter } from '../events/popup-close';
import { setLastSearchResultPageCookie } from '../cookies';
import InitAdManager from '../adverts';
import { updateAlertsStatus } from '../user/alerts';
import SideNavigation from '../navigation/side-nav';
import AccountControls from '../user/account-widgets';
import CollapsingElements from '../collapse';
import Dropdown from '../dropdown';
import SuggestLanguagePopup from '../suggestlanguage';
import SaveFromElement from '../user/save-from-element';
import InitCountryCodes from '../user/telephone';
import { InitPopupAltCloseMethods } from '../popups/close-handler';
import ExitIntent from '../user/exit-intent';
import SimpleForm from '../simpleform';
import requestIdleCallback from '../shims/requestIdleCallback';
import InitSelectElements from '../selects/global';
import { updateSavedCounter, onUpdateSavedStatus } from '../user/saved';
import InitAlertPopups from '../alert-popups';
import { storeSessionStartTime } from '../session/time';
import initSentry from '../logging/sentry';
import { getFetchAPI } from '../http';
import { initGlobalErrorLogger } from '../logging/errors';

window.nextDataURL;
window.prevDataURL;
window.savedCounterElement;

class GlobalContext {
    constructor() {
        initSentry();

        initGlobalErrorLogger();

        window.http = getFetchAPI();

        window.router = new Router();

        window.appStorage = new AppStorage();

        storeSessionStartTime();

        this._registerGlobalEvents();

        this._initialiseGlobalComponents();
    }

    _registerGlobalEvents(): void {
        window.addEventListener( 'pageshow', onPageShow );

        window.addEventListener( 'twc:newcontentloaded', onNewcontentloaded );

        window.addEventListener( 'twc:popup:open', onPopupOpen );

        window.addEventListener( 'twc:popup:close', onPopupClose );

        window.addEventListener( 'twc:popup:close:after', onPopupCloseAfter );

        window.addEventListener( 'twc:navigation', popupHandler );

        window.addEventListener( 'twc:update-saved-status', onUpdateSavedStatus );

        window.addEventListener( 'twc:update-saved-counter', updateSavedCounter );
    }

    _initialiseGlobalComponents(): void {
        InitAdManager();

        SideNavigation();

        AccountControls();

        SuggestLanguagePopup();

        SaveFromElement();

        InitCountryCodes();

        InitPopupAltCloseMethods();

        ExitIntent();

        InitSelectElements();

        InitAlertPopups();

        new CollapsingElements();

        new Dropdown();

        document.querySelectorAll( '.twc__simple-form' ).forEach( ( form: HTMLFormElement ) => SimpleForm( form ) );

        requestIdleCallback( updateAlertsStatus );

        // @ts-ignore
        Ladda.bind( 'button.ladda-button', {
            timeout: 750,
        } );

        document.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
            bubbles: true,
        } ) );

        if ( document.querySelector( '.flash' ) ) {
            document.addEventListener( 'click', ( event: Event ) => {
                if ( !event.target || !( event.target instanceof HTMLElement ) ) {
                    return;
                }

                const btn = event.target.closest( '.flash button' ) as HTMLButtonElement | null;

                if ( btn ) {
                    ( btn.closest( '.flash' ) as HTMLElement ).remove();
                }
            } );
        }

        if ( window.setSearchResultPageCookie && window.setSearchResultPageCookie === true ) {
            setLastSearchResultPageCookie();
        }

        document.querySelectorAll( '.gradient-loader' ).forEach( e => e.classList.remove( 'gradient-loader' ) );
    }
}

export default GlobalContext;
