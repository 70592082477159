import { createCookie } from '../cookies';
import { navigateTo } from '../http/redirect';
import { closeAllUtilityPopups } from '../popups/utils';

export default function SuggestLanguagePopup(): void {
    const container = document.querySelector( '.popup__container--language-suggest' );

    if ( container ) {
        createCookie( '_twc_suggestLang', 'no', 365 );

        container.querySelectorAll( 'button' ).forEach( ( btn: HTMLButtonElement ) => {
            btn.addEventListener( 'click', ( event: Event ) => {
                event.preventDefault();
                container.remove();
                closeAllUtilityPopups();
            }, { once: true } );
        } );
    }

    document.querySelectorAll( '.footer-links a[data-lang]' ).forEach( ( link: HTMLAnchorElement ) => {
        link.addEventListener( 'click', ( event: Event ) => {
            event.preventDefault();
            createCookie( '_twc_suggestLang', 'no', 365 );
            navigateTo( link.href );
        } );
    } );
}
