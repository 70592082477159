import * as Sentry from '@sentry/browser';

import {
    canAccessLocalStorage,
    canAccessSessionStorage,
} from '../storage/support';

var KeyStringError = new TypeError( 'key must be of type string' );
var ValueStringError = new TypeError( 'value must be of type string, consider .toString() or JSON.stringify()' );

/**
 * AppStorage is a wrapper around the window.sessionStorage
 * and window.localStorage objects.
 */
var AppStorage = function() {
    this.localStorageIsSupported = canAccessLocalStorage();
    this.sessionStorageIsSupported = canAccessSessionStorage();
    this.localStorageFallback = {};
    this.sessionStorageFallback = {};

    if ( !this.localStorageIsSupported || !this.sessionStorageIsSupported ) {
        Sentry.captureMessage( 'localStorage/sessionStorage is not supported' );
    }
};

/**
 * getLocalItem retrieves a vaue from sessionStorage or the fallback
 * @param {String} key - the key to retrieve the value from
 * @returns String - the value from the sessionStorage or the fallback
 */
AppStorage.prototype.getSessionItem = function( key ) {
    if ( typeof key !== 'string' ) {
        throw KeyStringError;
    }

    var value;

    if ( this.sessionStorageIsSupported ) {
        value = window.sessionStorage.getItem( key );
    }

    if ( !this.sessionStorageIsSupported ) {
        value = this.sessionStorageFallback[ key ];
    }

    if ( typeof value === 'undefined' ) {
        value = null;
    }

    return value;
};

/**
 * setSessionItem set a key/value pair in sessionStorage if supported.
 * Otherwise it will store the values in a fallback object based store.
 * @param {String} key - the key to store the value under
 * @param {*} value - the value to store, this can be any type but will be stringified by .toString()
 * @returns void
 */
AppStorage.prototype.setSessionItem = function( key, value ) {
    if ( typeof key !== 'string' ) {
        throw KeyStringError;
    }

    if ( typeof value !== 'string' ) {
        throw ValueStringError;
    }

    if ( !this.sessionStorageIsSupported ) {
        this.sessionStorageFallback[ key ] = value;
        return;
    }

    window.sessionStorage.setItem( key, value );
};

/**
 * getLocalItem retrieves a vaue from localStorage or the fallback
 * @param {String} key - the key to retrieve the value from
 * @returns String - the value from the localStorage or the default value
 */
AppStorage.prototype.getLocalItem = function( key ) {
    if ( typeof key !== 'string' ) {
        throw KeyStringError;
    }

    var value;

    if ( this.localStorageIsSupported ) {
        value = window.localStorage.getItem( key );
    }

    if ( !this.localStorageIsSupported ) {
        value = this.localStorageFallback[ key ];
    }

    if ( typeof value === 'undefined' ) {
        value = null;
    }

    return value;
};

/**
 * setLocalItem set a key/value pair in localStorage if supported.
 * Otherwise it will store the values in a fallback object based store.
 * @param {String} key - the key to store the value under
 * @param {*} value - the value to store, this can be any type but will be stringified by .toString()
 * @returns void
 */
AppStorage.prototype.setLocalItem = function( key, value ) {
    if ( typeof key !== 'string' ) {
        throw KeyStringError;
    }

    if ( typeof value !== 'string' ) {
        throw ValueStringError;
    }

    if ( !this.localStorageIsSupported ) {
        this.localStorageFallback[ key ] = value;
        return;
    }

    window.localStorage.setItem( key, value );
};

export default AppStorage;
