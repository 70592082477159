import { onDirectoryEnquiryClose } from '../events/popup-close-directory-enquiry';
import { addRecaptchaField, loadRecaptcha } from '../captcha/google';
import { resolveUsersVisitorInfo } from '../user/visitor-info';
import { getUser } from '../user/user';
import newValidator from '../validation';
import { setupEmailSuggestionFromResult } from '../validation/email-suggest';

function DirectoryEnquiryForm( form ) {
    var validator = null;

    if ( form.dataset.initialised && form.dataset.initialised === 'true' ) {
        return;
    }

    form.dataset.initialised = true;

    form.addEventListener( 'submit', function( event ) {
        event.preventDefault();

        var form = event.target;

        if ( !validator ) {
            validator = newValidator( form );
        }

        handleEnquiry( form );
    } );

    var handleEnquiry = async function( form ) {
        if ( form.dataset.isProcessing && form.dataset.isProcessing === 'true' ) {
            return;
        }

        var messageContainer = form.querySelector( '.messages' );

        form.dataset.isProcessing = true;

        validator.reset();

        var valid = validator.validate();
        var errors = validator.getErrors();

        if ( errors.length > 0 ) {
            var eventLabel = 'errors:' + errors.length;

            for ( var i = 0; i < errors.length; i++ ) {
                var name = errors[ i ].input.name;
                var value = errors[ i ].input.value;
                eventLabel += ';' + name + '=' + value;
            }

            window.dataLayerPush( {
                'event': 'directory',
                'action': 'enquiry-errors',
                'label': eventLabel,
                'value': 1,
            } );
        }

        if ( !valid ) {
            form.dataset.isProcessing = false;
            Ladda.stopAll();
            return;
        }

        messageContainer.innerHTML = '';

        var sendRequest = async function sendRequest() {
            const formData = new FormData( form );

            formData.append( 'ipcountry', ( await getUser().visitorInfo() ).country );

            var url = new URL( window.location.origin + form.getAttribute( 'action' ) );

            http( url, {
                method: 'POST',
                body: formData,
            } )
                .then( function( response ) {
                    if ( !response.ok ) {
                        throw new Error( 'dirctory enquiry failed with status ' + response.status );
                    }

                    return response.json();
                } )
                .then( function( response ) {
                    form.dataset.isProcessing = false;

                    Ladda.stopAll();

                    if ( response.didyoumean ) {
                        setupEmailSuggestionFromResult( {
                            emailSuggestion: response.didyoumean,
                            html: response.didyoumeanhtml,
                        }, form );
                        return;
                    }

                    if ( response.success ) {
                        document.body.insertAdjacentHTML( 'beforeend', response.data.response );

                        var closeBtn = document.querySelector( '[data-action="directory-enquiry-close"]' );
                        if ( closeBtn ) {
                            closeBtn.addEventListener( 'click', onDirectoryEnquiryClose );
                        }

                        twc.track( response.data.eventName, {
                            refId: response.data.refId,
                            dirs: JSON.parse( response.data.directory ),
                        } );

                        if ( form.dataset.analyticsEvent ) {
                            window.dataLayerPush( JSON.parse( form.dataset.analyticsEvent ) );
                        }

                        window.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
                            bubbles: true,
                        } ) );
                    } else if ( response.errors && response.errors.form ) {
                        messageContainer.insertAdjacentHTML( 'beforeend', '<div class="alert alert-warning">' + response.errors.form + '</div>' );

                        window.scroll( 0, messageContainer.getBoundingClientRect().top + window.scrollY - 200 );

                        window.dataLayerPush( {
                            'event': 'directory',
                            'action': 'enquiry-errors-server',
                            'label': 'server-failure:' + response.errors.form,
                            'value': 1,
                        } );
                    }
                } );
        };

        var captchaAction = form.dataset.captchaAction;

        async function resolveToken() {
            const token = await loadRecaptcha( captchaAction );
            addRecaptchaField( form[ 0 ], token );
        }

        await Promise.all( [
            resolveUsersVisitorInfo(),
            resolveToken(),
        ] );

        await sendRequest();
    };
}

export default DirectoryEnquiryForm;
