import Logger from '../logging/logger';
import { getCurrentTimestamp } from '../time/time';

export function storeSessionStartTime(): void {
    if ( window.appStorage.getSessionItem( 'sessionStartTime' ) ) {
        return;
    }

    const ts = getCurrentTimestamp().toString();

    Logger.info( 'Setting session start time', { timestamp: ts } );

    window.appStorage.setSessionItem( 'sessionStartTime', ts );
}

export function getSessionStartTime(): number {
    return parseInt( window.appStorage.getSessionItem( 'sessionStartTime' ) );
}
