import GlobalContext from '../bootstrap';
import FeedbackForm from '../feedback';
import HomepageHeroSearch from '../homepage/hero-search';
import InitLazyLoadedStyles from '../lazyloading/styles';
import InitTypeaheads from '../typeahead';

class Homepage extends GlobalContext {
    constructor() {
        super();
        this._initialiseComponents();
    }

    _initialiseComponents(): void {
        InitLazyLoadedStyles();

        InitTypeaheads();

        FeedbackForm();

        const heroForm = document.querySelector( '.searchhero__form' ) as HTMLFormElement | null;

        if ( heroForm ) {
            new HomepageHeroSearch( heroForm );
        }
    }
}

if ( !window.homepage ) {
    window.homepage = new Homepage();
}
