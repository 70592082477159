import { urlWithLanguage } from '../url';

function getUtilityPopupContainer(): HTMLElement {
    let popupContainer = document.querySelector( '#utility-popup-container' ) as HTMLElement | null;

    if ( !popupContainer ) {
        const template = document.createElement( 'template' );
        template.innerHTML = '<div id="utility-popup-container" class="popup__wrapper"></div>';
        popupContainer = document.body.appendChild( template.content.children[ 0 ] ) as HTMLElement;
    }

    return popupContainer;
}

async function getHTML( id: string, params: Record<string, string> = {} ): Promise<HTMLElement> {
    const url = urlWithLanguage( new URL( window.location.origin ), `/html-content/${id}` );

    Object.keys( params ).forEach( ( key: string ) => url.searchParams.set( key, params[ key ] ) );

    const response = await window.http( url );

    if ( !response.ok ) {
        throw new Error( `failed fetching html ${id}` );
    }

    const template = document.createElement( 'template' );
    template.innerHTML = await response.text();

    return template.content.children[ 0 ] as HTMLElement;
}

export {
    getUtilityPopupContainer,
    getHTML,
};
