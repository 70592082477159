export default function InitLazyLoadedStyles(): void {
    const observer = new IntersectionObserver( function( entries: IntersectionObserverEntry[], observer: IntersectionObserver ) {
        entries.forEach( ( entry: IntersectionObserverEntry ): void => {
            if ( !entry.isIntersecting ) {
                return;
            }

            const target = entry.target as HTMLElement;

            target.style.backgroundImage = `url("${target.dataset.lazyBg}")`;

            target.removeAttribute( 'data-lazy-bg' );

            observer.unobserve( entry.target );
        } );
    }, {
        rootMargin: '50px 0px',
    } );

    document.querySelectorAll( '[data-lazy-bg]' ).forEach( ( element: HTMLElement ): void => {
        observer.observe( element );
    } );
}
