import { trackInactivity } from '../user/activity';
import { OpenAlertEvent } from './popup-manager';
import Logger from '../logging/logger';

const DEFAULT_INACTIVITY_TIMEOUT = 60;

const DEFAULT_EXTENDED_INACTIVITY_TIMEOUT = 90;

export function getInactivityTimeout(): number {
    if ( window.inactivityTimeout ) {
        return window.inactivityTimeout;
    }
    return DEFAULT_INACTIVITY_TIMEOUT;
}

export function getExtendedInactivityTimeout(): number {
    if ( window.extendedInactivityTimeout ) {
        return window.extendedInactivityTimeout;
    }
    return DEFAULT_EXTENDED_INACTIVITY_TIMEOUT;
}

type TimeoutChangeEvent = {
    timeout: number;
}

class InactivityController {
    _inactivityTimeout: number;

    constructor() {
        this._inactivityTimeout = getInactivityTimeout();

        Logger.info( 'Setting up alert poppup inactivity trigger', {
            inactivityTimeout: `${this._inactivityTimeout}s`,
        } );

        this._start();

        window.addEventListener( 'twc:alertable:timeout-change', this._onTimeoutUpdate.bind( this ) );
    }

    /**
     * _start initiates inactivity tracking
     * @returns {void}
     */
    _start(): void {
        Logger.info( 'Starting inactivity tracking', {
            timeout: `${this._inactivityTimeout}s`,
        } );

        trackInactivity( this._inactivityTimeout, () => {
            document.dispatchEvent( new CustomEvent<OpenAlertEvent>( 'twc:popup:open-property-alert', {
                bubbles: true,
                detail: {
                    id: 'inactivity',
                    onPopupClosedCallback: this._start.bind( this ),
                    onPopupBlockedCallback: this._start.bind( this ),
                },
            } ) );
        } );
    }

    /**
     * _onTimeoutUpdate handles updating the inactivity timeout limit
     * @param {CustomEvent<TimeoutChangeEvent>} event - the event triggered
     * @returns {void}
     */
    _onTimeoutUpdate( event: CustomEvent<TimeoutChangeEvent> ): void {
        if ( !event.detail || !event.detail.timeout ) {
            throw new Error( 'a period value must be provided to update' );
        }

        const newTimeout = event.detail.timeout;

        Logger.info( 'Updating inactivity timeout', {
            from: `${this._inactivityTimeout}s`,
            to: `${newTimeout}s`,
        } );

        this._inactivityTimeout = newTimeout;

        this._start();
    }
}

export default InactivityController;
