import { removeElements } from '../../dom/remove';
import { parseEscapedJSONString } from '../../json/parse';
import { isMapMode } from '../../maps/util';
import Typeahead, { AwesompleteEvent } from '../typeahead';
import { createBaseLI } from './common';

export class LocationSuggestion {
    label: string;
    value: string;
    id: string;
    type: string;
    typeDescription: string;
    isGeocoded: boolean;
    isIsland: boolean;
    longitude: number;
    latitude: number;
    location: string;
    icon: string;
    suggestionType = 'location';
    _item?: HTMLLIElement;

    select( ctx: Typeahead, event: AwesompleteEvent ): boolean {
        return true;
    }

    selected( ctx: Typeahead ): void {
        ctx.startLoading();

        selectTypeaheadOption( ctx._form, {
            label: this.label,
            value: this.value,
            type: this.type,
            isIsland: this.isIsland,
            isGeocoded: this.isGeocoded,
            longitude: this.longitude,
            latitude: this.latitude,
            location: this.location,
        } );

        const mapMode = isMapMode();

        if ( mapMode || !ctx._autoSubmit ) {
            ctx.stopLoading();
        }

        if ( mapMode && ctx._autoSubmit ) {
            ctx._form.dispatchEvent( new CustomEvent( 'twc:mapsearch:form-submit', { bubbles: true } ) );
        }

        if ( !mapMode && ctx._autoSubmit ) {
            ctx._form.submit();
        }
    }

    item( highlightElement: HTMLElement, ref: string, index: number ): HTMLLIElement {
        if ( !this._item ) {
            this._item = createBaseLI( ref, index, this );
            this._item.appendChild( highlightElement );

            if ( this.typeDescription && this.typeDescription.length > 0 ) {
                const desc = document.createElement( 'span' );
                desc.className = 'typeahead__location-description';
                desc.innerText = this.typeDescription;
                this._item.appendChild( desc );
            }
        }

        return this._item;
    }
}

export default function newLocationSuggestion( obj: any ): LocationSuggestion {
    const s = new LocationSuggestion();

    s.value = obj.value || obj.id || '';
    s.label = obj.label || obj.name || '';
    s.type = obj.type || '';
    s.typeDescription = obj.typeDescription || '';
    s.isGeocoded = obj.isGeocoded;
    s.isIsland = obj.isIsland;
    s.longitude = parseFloat( obj.longitude );
    s.latitude = parseFloat( obj.latitude );

    if ( typeof obj.location === 'string' ) {
        s.location = JSON.stringify( parseEscapedJSONString( obj.location ) );
    } else {
        s.location = JSON.stringify( obj.location );
    }

    return s;
}

interface typeaheadSelection {
    label: string;
    value: string;
    type?: string;
    typeDescription?: string;
    isGeocoded?: boolean;
    isIsland?: boolean;
    longitude?: number;
    latitude?: number;
    location?: string;
}

export function selectTypeaheadOption( form: HTMLFormElement, selection: typeaheadSelection ): void {
    removeElements( 'input[name=area]' );
    removeElements( 'input[name=viewmode]' );

    const formRadiusContainers = form.querySelectorAll( '.searchpopup__location-radius' );
    const formRadiusInputs = form.querySelectorAll( '[name=radius]' ) as NodeListOf<HTMLInputElement>;

    if ( selection.isGeocoded ) {
        formRadiusInputs.forEach( radius => {
            radius.value = '';

            if ( radius.nodeName === 'SELECT' ) {
                radius.disabled = false;
                radius.classList.remove( 'has-value' );
            }
        } );

        formRadiusContainers.forEach( container => container.classList.remove( 'd-none' ) );
    } else {
        formRadiusInputs.forEach( radius => {
            radius.value = '';

            if ( radius.nodeName === 'SELECT' ) {
                radius.classList.remove( 'has-value' );
                radius.disabled = true;
            }
        } );

        formRadiusContainers.forEach( container => container.classList.add( 'd-none' ) );
    }

    form.querySelectorAll( 'input[name=locationFreeText]' ).forEach( ( input: HTMLInputElement ) => {
        input.setAttribute( 'value', selection.label || '' );
        input.dataset.tsGeo = selection.isGeocoded ? selection.isGeocoded.toString() : '';
        input.dataset.previousValue = '';

        const resetContainer = input.closest( '.reseteable' );

        if ( resetContainer ) {
            const reset = resetContainer.querySelector( '.reset-field' ) as HTMLButtonElement | null;

            if ( reset && selection.value.length > 0 ) {
                reset.style.display = '';
            }
        }
    } );

    form.querySelectorAll( 'input[name=locationId]' ).forEach( ( input: HTMLInputElement ) => {
        input.setAttribute( 'value', selection.value || '' );
        input.dataset.id = selection.value || '';
        input.dataset.type = selection.type || '';
        input.dataset.isIsland = selection.isIsland ? selection.isIsland.toString() : '';
        input.dataset.isGeocoded = selection.isGeocoded ? selection.isGeocoded.toString() : '';
        input.dataset.longitude = selection.longitude ? selection.longitude.toString() : '';
        input.dataset.latitude = selection.latitude ? selection.latitude.toString() : '';
        input.dataset.location = selection.location || '';
    } );
}
