import Typeahead, { AwesompleteEvent } from '../typeahead';
import { createBaseLI } from './common';
import { isMapMode } from '../../maps/util';
import { locationFilters } from '../../property/filters';

class SimpleSuggestion {
    label: string;
    value: string;
    icon: string;
    suggestionType = 'simple';
    _item?: HTMLLIElement;

    select( ctx: Typeahead, event: AwesompleteEvent ): boolean {
        return true;
    }

    selected( ctx: Typeahead ): void {
        ctx._valueInput.value = this.value;

        if ( this.value === 'spain' ) {
            const formRadiusContainers = ctx._form.querySelectorAll( '.searchpopup__location-radius' );
            const formRadiusInputs = ctx._form.querySelectorAll( '[name=radius]' ) as NodeListOf<HTMLInputElement>;

            formRadiusContainers.forEach( el => el.classList.add( 'd-none' ) );
            formRadiusInputs.forEach( radius => {
                radius.value = '';

                if ( radius.nodeName === 'SELECT' ) {
                    radius.disabled = true;
                    radius.classList.remove( 'has-value' );
                }
            } );

            const area: HTMLInputElement | null = ctx._form.querySelector( 'input[name=area]' );

            if ( area ) {
                area.value = '';
            }
        }

        if ( !this.value.startsWith( 'location-filters' ) && ( ctx._form.id === 'property-search-form-base' || ctx._form.id === 'property-search-form-popup' ) ) {
            locationFilters.all().forEach( ( name: string ) => {
                ctx._form.querySelectorAll( `[name=${name}]` ).forEach( ( input: HTMLInputElement ) => input.value = '' );
            } );
        }

        if ( ctx._autoSubmit && isMapMode() ) {
            ctx._form.dispatchEvent( new CustomEvent( 'twc:mapsearch:form-submit', { bubbles: true } ) );
            return;
        }

        if ( ctx._autoSubmit ) {
            ctx.startLoading();
            ctx._form.submit();
            return;
        }

        ctx._valueInput.dispatchEvent( new Event( 'change' ) );
    }

    item( highlightElement: HTMLElement, ref: string, index: number ): HTMLLIElement {
        if ( !this._item ) {
            this._item = createBaseLI( ref, index, this );
            this._item.appendChild( highlightElement );
        }
        return this._item;
    }
}

export default function newSimpleSuggestion( obj: any ): SimpleSuggestion {
    const s = new SimpleSuggestion();
    s.value = obj.value || obj.id || '';
    s.label = obj.name || obj.label || '';
    s.icon = obj.icon || '';
    return s;
}
