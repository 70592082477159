import { parseBool } from '../strconv/boolean';
import { parseCoordinateString } from '../strconv/spatial';
import { MonthsShort } from '../time/months';

type ParamMapping = {
    arrays: Array<string>;
    ints: Array<string>;
    floats: Array<string>;
    bools: Array<string>;
    strings: Array<string>;
    all(): Array<string>;
}

export const locationFilters: ParamMapping = {
    arrays: [
        'locationTags',
        'weatherMonths',
        'sunHours',
        ...MonthsShort.map( ( month: string ) => `${month}SunHours` ),
        'rainDays',
        ...MonthsShort.map( ( month: string ) => `${month}RainDays` ),
        'windSpeeds',
        ...MonthsShort.map( ( month: string ) => `${month}WindSpeeds` ),
        'beachTags',
        'beachCompositions',
        'beachSandTypes',
        'beachRankGroups',
        'golfTags',
        'golfRankGroups',
        'skiRankGroups',
        'internationalSchoolRankGroups',
        'internationalSchoolAges',
        'internationalSchoolLanguages',
        'internationalSchoolCurriculums',
        'airportRankGroups',
        'airportTags',
        'hikingRankGroups',
    ],
    ints: [
        'minPopulation',
        'maxPopulation',
        'minDistanceToOtherPopulation',
        'maxDistanceToOtherPopulation',
        'minPopulationOther',
        'maxPopulationOther',
        'minDistanceToBeach',
        'maxDistanceToBeach',
        'minDistanceToGolf',
        'maxDistanceToGolf',
        'minDistanceToSki',
        'maxDistanceToSki',
        'minDistanceToInternationalSchool',
        'maxDistanceToInternationalSchool',
        'minDistanceToAirport',
        'maxDistanceToAirport',
        'hikingMinAltitudeMax',
        'hikingMaxAltitudeMax',
        'maxDistanceToHiking',
        'minDistanceToTrainStation',
        'maxDistanceToTrainStation',
        'minDistanceToHospital',
        'maxDistanceToHospital',
        'minNationalityPercentage',
        'maxNationalityPercentage',
        'minNationalityPercentageOther',
        'maxNationalityPercentageOther',
    ],
    floats: [
        'minAvgMinTemperature',
        ...MonthsShort.map( ( month: string ) => `${month}MinAvgMinTemperature` ),
        'maxAvgMinTemperature',
        ...MonthsShort.map( ( month: string ) => `${month}MaxAvgMinTemperature` ),
        'minAvgMaxTemperature',
        ...MonthsShort.map( ( month: string ) => `${month}MinAvgMaxTemperature` ),
        'maxAvgMaxTemperature',
        ...MonthsShort.map( ( month: string ) => `${month}MaxAvgMaxTemperature` ),
        'minUrbanIBI',
        'maxUrbanIBI',
    ],
    bools: [
        'mustHaveAirport',
        'mustHaveSkiing',
        'mustHaveHospital',
        'mustHaveTrainStation',
        'mustHaveHiking',
    ],
    strings: [
        'nationalities',
        'nationalitiesOther',
    ],
    all: function(): Array<string> {
        return [
            ...locationFilters.arrays,
            ...locationFilters.bools,
            ...locationFilters.floats,
            ...locationFilters.ints,
            ...locationFilters.strings,
        ];
    },
};

export const filters: ParamMapping = {
    arrays: [
        'tags',
        'types',
        ...locationFilters.arrays,
    ],
    ints: [
        'currentLocationID',
        'minprice',
        'maxprice',
        'build',
        'plot',
        'radius',
        ...locationFilters.ints,
    ],
    floats: [
        ...locationFilters.floats,
    ],
    bools: [
        ...locationFilters.bools,
    ],
    strings: [
        'offer',
        'order',
        'beds',
        'baths',
        'locationFreeText',
        'mode',
        'viewMode',
        'currentLocationType',
        ...locationFilters.strings,
    ],
    all: function(): Array<string> {
        return [
            ...filters.arrays,
            ...filters.bools,
            ...filters.floats,
            ...filters.ints,
            ...filters.strings,
        ];
    },
};

export const keywordLocationIDs: Array<string> = [
    'spain',
    'map-area',
    'search-this-area',
    'draw-your-search',
    'location-filters',
];

export default class PropertySearchFilter {
    offer:                  string = '';
    order:                  string = '';
    beds:                   string = '';
    baths:                  string = '';
    mode:                   string = '';
    viewMode:               string = '';

    locationValue:          string = '';
    locationFreeText:       string = '';

    currentLocationID:      number | null = null;
    currentLocationType:    string = '';

    locationTags:                           Array<string> = [];
    weatherMonths:                          Array<string> = [];
    sunHours:                               Array<string> = [];
    rainDays:                               Array<string> = [];
    windSpeeds:                             Array<string> = [];
    types:                                  Array<string> = [];
    tags:                                   Array<string> = [];
    populationTags:                         Array<string> = [];
    populationDensities:                    Array<string> = [];
    otherPopulationTags:                    Array<string> = [];
    otherPopulationDensities:               Array<string> = [];
    beachCompositions:                      Array<string> = [];
    beachSandTypes:                         Array<string> = [];
    beachRankGroups:                        Array<string> = [];
    skiRankGroups:                          Array<string> = [];
    golfRankGroups:                         Array<string> = [];
    airportRankGroups:                      Array<string> = [];
    internationalSchoolRankGroups:          Array<string> = [];

    minprice:                               number | null = null;
    maxprice:                               number | null = null;
    build:                                  number | null = null;
    plot:                                   number | null = null;
    radius:                                 number | null = null;
    minPopulation:                          number | null = null;
    maxPopulation:                          number | null = null;
    minUrbanIBI:                            number | null = null;
    maxUrbanIBI:                            number | null = null;
    minPopulationOther:                     number | null = null;
    maxPopulationOther:                     number | null = null;
    minDistanceToOtherPopulationDensities:  number | null = null;
    maxDistanceToOtherPopulationDensities:  number | null = null;
    minAvgMinTemperature:                   number | null = null;
    maxAvgMinTemperature:                   number | null = null;
    minAvgMaxTemperature:                   number | null = null;
    maxAvgMaxTemperature:                   number | null = null;
    minDistanceToBeach:                     number | null = null;
    maxDistanceToBeach:                     number | null = null;
    minDistanceToGolf:                      number | null = null;
    maxDistanceToGolf:                      number | null = null;
    minDistanceToSki:                       number | null = null;
    maxDistanceToSki:                       number | null = null;
    minDistanceToInternationalSchool:       number | null = null;
    maxDistanceToInternationalSchool:       number | null = null;
    minDistanceToAirport:                   number | null = null;
    maxDistanceToAirport:                   number | null = null;

    mustHaveAirport:        boolean = false;
    mustHaveSkiing:         boolean = false;
    mustHaveHospital:       boolean = false;
    mustHaveTrainStation:   boolean = false;
    mustHaveHiking:         boolean = false;

    area: Array<Array<number>> = [];

    static equal( filterA: PropertySearchFilter, filterB: PropertySearchFilter, excludeParams = [] ): boolean {
        const paramsA = filterA.asURLSearchParams();
        const paramsB = filterB.asURLSearchParams();

        if ( excludeParams.length > 0 ) {
            excludeParams.forEach( param => {
                paramsA.delete( param );
                paramsB.delete( param );
            } );
        }

        return paramsA.toString() === paramsB.toString();
    }

    set( field: string, value: any ): void {
        this[ field ] = value;
    }

    toString(): string {
        return this.asURLSearchParams().toString();
    }

    asURLSearchParams( joinArrayValues: boolean = true ): URLSearchParams {
        const params = new URLSearchParams();

        const arrayValues = filters.arrays.filter( key => !!this[ key ] && ( this[ key ] as Array<string> ).length > 0 );

        if ( joinArrayValues ) {
            arrayValues.forEach( key => params.append( key, ( this[ key ] as Array<string> ).join( ',' ) ) );
        } else {
            arrayValues.forEach( key => ( this[ key ] as Array<string> ).forEach( value => params.append( key, value ) ) );
        }

        filters.bools.filter( key => this[ key ] === true ).forEach( key => params.append( key, ( this[ key ] as boolean ).toString() ) );

        filters.ints.filter( key => !!this[ key ] ).forEach( key => params.append( key, ( this[ key ] as number ).toString() ) );

        filters.floats.filter( key => !!this[ key ] ).forEach( key => params.append( key, ( this[ key ] as number ).toString() ) );

        filters.strings.filter( key => !!this[ key ] && this[ key ].length > 0 ).forEach( key => params.append( key, this[ key ] as string ) );

        if ( this.area.length > 0 ) {
            params.set( 'area', this.area.toString() );
        }

        params.sort();

        return params;
    }

    anyLocationFilters(): boolean {
        for ( const key of locationFilters.arrays ) {
            const value = this[ key ] as Array<any>;

            if ( value && value.length > 0 ) {
                return true;
            }
        }

        for ( const key of locationFilters.ints ) {
            const value = this[ key ] as number | null;

            if ( value !== null ) {
                return true;
            }
        }

        for ( const key of locationFilters.floats ) {
            const value = this[ key ] as number | null;

            if ( value !== null ) {
                return true;
            }
        }

        for ( const key of locationFilters.bools ) {
            const value = this[ key ] as boolean;

            if ( value ) {
                return true;
            }
        }

        for ( const key of locationFilters.strings ) {
            const value = this[ key ] as string;

            if ( value && value.length > 0 ) {
                return true;
            }
        }

        return false;
    }

    static fromURLSearchParams( params: URLSearchParams ): PropertySearchFilter {
        const filter = new PropertySearchFilter();

        filters.arrays.forEach( key => {
            const value: string | null = params.get( key );
            if ( value === null || value.length === 0 ) {
                filter[ key ] = [];
            } else {
                filter[ key ] = value.split( ',' );
            }
        } );

        filters.ints.forEach( key => {
            const value: string | null = params.get( key );
            if ( value === null || value.length === 0 ) {
                filter[ key ] = null;
            } else {
                filter[ key ] = parseInt( value );
            }
        } );

        filters.floats.forEach( key => {
            const value: string | null = params.get( key );
            if ( value === null || value.length === 0 ) {
                filter[ key ] = null;
            } else {
                filter[ key ] = parseFloat( value );
            }
        } );

        filters.bools.forEach( key => {
            const value: string | null = params.get( key );
            if ( value === null ) {
                filter[ key ] = false;
            } else {
                filter[ key ] = parseBool( value );
            }
        } );

        filters.strings.forEach( key => {
            const value: string | null = params.get( key );
            if ( value === null || value.length === 0 ) {
                filter[ key ] = '';
            } else {
                filter[ key ] = value;
            }
        } );

        if ( params.get( 'area' ) !== null && ( params.get( 'area' ) as string ).length > 0 ) {
            filter.area = parseCoordinateString( params.get( 'area' ) as string );
        }

        if ( filter.anyLocationFilters() ) {
            filter.locationValue = 'location-filters';

            if ( filter.currentLocationID && filter.currentLocationID > 0 ) {
                filter.locationValue += '+' + filter.currentLocationID.toString();
            }
        }

        return filter;
    }

    static fromForm( form: HTMLFormElement ): PropertySearchFilter {
        const filter = new PropertySearchFilter();

        const data = new FormData( form );

        filters.arrays.forEach( key => {
            let values = data.getAll( key ) as Array<string>;

            if ( values.length > 0 ) {
                values = values.filter( ( str: string ) => str.length > 0 );
            }

            if ( values.length === 0 ) {
                filter[ key ] = [];
            } else {
                filter[ key ] = values;
            }
        } );

        filters.ints.forEach( key => {
            const value = data.get( key ) as string;
            if ( value === null || value.length === 0 ) {
                filter[ key ] = null;
            } else {
                filter[ key ] = parseInt( value );
            }
        } );

        filters.floats.forEach( key => {
            const value = data.get( key ) as string;
            if ( value === null || value.length === 0 ) {
                filter[ key ] = null;
            } else {
                filter[ key ] = parseFloat( value );
            }
        } );

        filters.bools.forEach( ( key: string ) => {
            const value = data.get( key ) as string | null;
            if ( value === null || value.length === 0 ) {
                filter[ key ] = false;
            } else {
                filter[ key ] = parseBool( value );
            }
            filter[ key ];
        } );

        filters.strings.forEach( key => {
            const value = data.get( key ) as string | null;
            if ( value === null || value.length === 0 ) {
                filter[ key ] = '';
            } else {
                filter[ key ] = value;
            }
        } );

        if ( data.get( 'area' ) !== null && ( data.get( 'area' ) as string ).length > 0 ) {
            filter.area = parseCoordinateString( ( data.get( 'area' )  as string ) );
        }

        const locationIDValue = data.get( 'locationId' ) as string | null;

        if ( locationIDValue ) {
            filter.locationValue = locationIDValue;
        }

        if ( locationIDValue !== null && locationIDValue.length > 0 && !keywordLocationIDs.includes( locationIDValue ) ) {
            filter.currentLocationID = parseInt( locationIDValue );

            const locationInput: HTMLInputElement = form.querySelector( 'input[name="locationId"]' ) as HTMLInputElement;

            filter.currentLocationType = locationInput.dataset.type || '';
        }

        if ( locationIDValue !== null && locationIDValue.length > 0 && locationIDValue.startsWith( 'location-filters+' ) ) {
            filter.currentLocationID = parseInt( locationIDValue.split( '+' )[ 1 ] );
        }

        return filter;
    }
}

export function resetSearchForm( container: HTMLElement, fieldsToIgnore: Array<string> = [] ): void {
    if ( !container ) {
        return;
    }

    container.querySelectorAll( 'input[type=hidden]' ).forEach( ( hiddenInput: HTMLInputElement ) => {
        if ( hiddenInput.dataset.resetableWithForm === 'false' ) {
            return;
        }
        if ( fieldsToIgnore.includes( hiddenInput.name ) ) {
            return;
        }
        hiddenInput.remove();
    } );

    const checkboxes: NodeListOf<HTMLInputElement> = container.querySelectorAll( 'input[type=checkbox]' );
    checkboxes.forEach( checkbox => {
        if ( checkbox.dataset.resetableWithForm === 'false' ) {
            return;
        }

        if ( fieldsToIgnore.includes( checkbox.name ) ) {
            return;
        }

        const enabling: boolean = ( !!checkbox.dataset.defaultState && checkbox.dataset.defaultState === 'checked' );

        if ( enabling ) {
            checkbox.checked = true;
        } else {
            checkbox.checked = false;
        }

        const parent = checkbox.parentElement;

        if ( !parent ) {
            return;
        }

        const parentClasses = parent.className.split( ' ' );
        const activeClass = parentClasses.find( pClass => /__group--active/.test( pClass ) );

        if ( !enabling && activeClass ) {
            parent.classList.remove( activeClass );
        }

        const groupClass = parentClasses.find( pClass => /__group/.test( pClass ) );

        if ( enabling && groupClass ) {
            parent.classList.add( `${groupClass}--active` );
        }
    } );

    const selects: NodeListOf<HTMLSelectElement> = container.querySelectorAll( 'select' );
    selects.forEach( select => {
        if ( select.dataset.resetableWithForm === 'false' ) {
            return;
        }
        if ( fieldsToIgnore.includes( select.name ) ) {
            return;
        }
        if ( select.dataset.defaultSelected && select.dataset.defaultSelected.length > 0 ) {
            select.value = select.dataset.defaultSelected;
        } else {
            select.value = '';
            select.classList.remove( 'has-value' );
        }
    } );
}
